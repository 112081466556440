<template>
  <div>
    <v-list
      two-line
      class="sub-plan-item"
    >
      <v-list-item
        :key="'sub-plan'"
      >
        <v-list-item-content>
          <v-list-item-title v-text="'$'+total_price/100 +'/month'" />
          <v-list-item-subtitle v-text="sub_platform_names" />
        </v-list-item-content>
        <v-list-item-action>
          <v-btn text class="text-capitalize" small @click="change">
            Change
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'SubPlanItem',
  props: {
    change: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    sub_platform_names() {
      const sub = this.$store.state.user.sub_data
      return sub.map(item => item.platform_name).join(';')
    },
    total_price() {
      const sub = this.$store.state.user.sub_data
      return sub.map(item => {
        return item.check ? item.discount_price : 0
      }).reduce((acc, val) => acc + val)
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-plan-item{
  text-align: justify;
  padding: 0px;
  .v-btn{
    font-weight: 800;
    font-size: 15px;
    color:#8C9EFF;
    letter-spacing: 0.02em;
  }
  .v-list-item__title{
    font-weight: 600;
  }
}

</style>
