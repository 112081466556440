<template>
  <v-container class="max-container">
    <top-banner :cancel="trial7dayHandle" :show-btn="true" :right-text="'Skip'" />
    <v-card class="payment-box" flat dark color="black">
      <add-payment-form ref="paymentForm" />
      <sub-plan-item ref="subPlanItem" :change="changePlan" />
      <div class="spacer-box" />
      <v-btn block depressed large class="tile text-capitalize channel-button btn-add-payment" :loading="loading" @click="startSub">Start 7 Days Free Trial</v-btn>
      <div class="authcard-message">
        You will be charged only after the 7-days free trial.You can cancel at anytime.
        Your card details are sent to <strong>Stripe</strong> via secure SSL connection for payment processing.
        We do not store your card information in our servers.To verify your credit card we will try charge it for ${{ try_charge_amount/100 }} and immediately refund it.
      </div>
    </v-card>
    <c-snack-bar ref="csnackbar" />
    <over-dialog ref="overdialog" :ok-handle="createSub" />
    <confirm-dialog ref="confirmdialog" :ok-handle="backSelect" />
    <over-lay-loading ref="overlayloading" />
  </v-container>
</template>

<script>

// eslint-disable-next-line
import { addPayment, subscription } from '@/api/user'
import CSnackBar from '../components/snackbar'
import AddPaymentForm from '../components/addPaymentForm.vue'
import SubPlanItem from '../components/subPlanItem.vue'
import OverDialog from '../components/overDialog'
import ConfirmDialog from '../components/overDialog'
import OverLayLoading from '../components/overLayLoading'
import TopBanner from '../components/topBanner'

import logo from '@/assets/logo.png'

export default {
  name: 'CreditOption',
  components: {
    CSnackBar,
    AddPaymentForm,
    SubPlanItem,
    OverDialog,
    ConfirmDialog,
    OverLayLoading,
    TopBanner
  },
  data: () => ({
    loading: false,
    logo: logo
  }),
  computed: {
    trial7day() {
      const sub = this.$store.state.user.userInfo.subscription
      return !sub || (sub && sub.status === 'trial_1' && sub.trial_end > Math.floor(Date.now() / 1000))
    },
    newUser() {
      const sub = this.$store.state.user.userInfo.subscription
      return !sub
    },
    try_charge_amount() {
      const sub_data = this.$store.state.user.sub_data
      let try_amount = 100
      if (sub_data.length > 0) {
        try_amount = sub_data
          .map((item) => {
            return Object.keys(item).includes('discount_price') ? item.discount_price : 0
          })
          .reduce((acc, val) => acc + val)
      }
      return try_amount === 0 ? 100 : try_amount
    }
  },
  created() {
    this.$nextTick(() => {
      const sub = this.$store.state.user.userInfo.subscription
      if (sub) {
        this.$router.push({ name: 'mychannel' })
      }
    })
  },
  methods: {
    async startSub() {
      if (!this.$refs.paymentForm.validate()) {
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      const data = this.$refs.paymentForm.getPaymentData()
      try {
        const res = await addPayment({ trial_check: true, try_charge_amount: this.try_charge_amount, card: data })
        if (res.code !== 0) {
          this.showNackBar('error', res.message, 6000)
          this.loading = false
          return
        } else {
          await this.createSub()
        }
      } catch (error) {
        this.showNackBar('error', 'Some errors have occurred, please try again later.', 6000)
      }
    },
    trial7dayHandle() {
      if (!this.newUser) {
        this.showNackBar('warning', 'You are not a new user and cannot perform this operation.')
        return
      }

      if (this.$store.state.user.sub_data.length > 1) {
        this.$refs.confirmdialog.controlDialog(
          true,
          'Users without a credit card can only choose one platform for a free trial, please go back and choose again.',
          'Confirm',
          false,
          true,
          'Re-select'
        )
        return
      }

      this.$refs.overlayloading.controlShow(true)
      const platform_list = this.$store.state.user.sub_data.map(item => item.platform_name)
      subscription({ 'operations': 'trial_1', 'platform_list': platform_list }).then(async(res) => {
        this.$refs.overlayloading.controlShow(false)
        if (res.code === 0) {
          this.showNackBar('info', res.message)
          await this.$store.dispatch('user/getInfo')
          this.$router.push({ name: 'InstallApp' })
          return
        }
      }).finally(() => {
        this.$refs.overlayloading.controlShow(false)
      })
    },
    showNackBar(color, message, timeout = 5000) {
      this.$refs.csnackbar.show(message, color, timeout)
    },
    changePlan() {
      this.$router.push({ path: '/signup/subplan' })
    },
    async createSub(is_active) {
      const operations = await this.getOperations(is_active)
      const platform_list = this.$store.state.user.sub_data.map(item => item.platform_name)
      subscription({ 'operations': operations, 'platform_list': platform_list }).then(async(res) => {
        if (res.code === 0) {
          if (is_active) {
            this.$refs.overdialog.controlLoading(false)
            this.$refs.overdialog.controlShowDialog(false)
          }
          this.showNackBar('info', res.message)
          await this.$store.dispatch('user/getInfo')
          this.$router.push({ name: 'InstallApp' })
          return
        }
        // 提示有绑定支付方式， 但无试用机会, 是否进入正式订阅 (active) 状态
        // eslint-disable-next-line
        if (res.code !== 0 && res.hasOwnProperty('data') && res.data && res.data.hasOwnProperty('card')&& res.data.hasOwnProperty('trial') && res.data['card'] && !res.data['trial']) {
          this.showNackBar('info', res.message)
          this.$refs.overdialog.controlDialog(true, res.data.active_tip)
          return
        }

        if (res.code !== 0) {
          this.showNackBar('error', res.message)
          return
        }

        this.showNackBar('error', res.message)
      }, () => {
        this.showNackBar('error', 'Some errors have occurred, please try again later.')
      }).finally(() => {
        this.loading = false
        if (is_active) {
          this.$refs.overdialog.controlLoading(false)
          this.$refs.overdialog.controlShowDialog(false)
        }
      })
    },
    async getOperations(is_active) {
      // is_active: 是否进入正式订阅（active）状态
      if (is_active) {
        return 'active'
      }
      return 'trial_2'
    },
    signOut() {
      this.$store.dispatch('user/FedLogOut').then(res => {
        this.$router.push({ 'name': 'login' })
      })
    },
    backSelect() {
      this.$router.push({ 'name': 'SubPlan' })
    }
  }
}
</script>
<style scoped lang="scss">

.v-card__subtitle, .v-card__text, .v-card__title{
  padding-left: 2px!important;
  padding-bottom:2px;
  padding-right: 0px;
}

.container{
  text-align: -moz-center;
  text-align: center;
  text-align: -webkit-center;
}

.payment-box{
  max-width: 390px;
}

.trial-box{
  width: 100%;
  text-align: center;
  .v-btn{
    font-weight: bold;
    letter-spacing: 0.08em;
    color: #E040FB;
  }
}

.tip-box{
  width: 100%;
  height: 50px;
  background: #EFEBE9;
}

.channel-button {
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
}

.card-title-text{
  color: #eae5e5!important;
  text-align: initial;
  word-break: break-word;
}

.authcard-message{
  padding-top: 10px;
  text-align: initial;
  color: rgba(255, 255, 255, 0.7);
}

.spacer-box{
  width: 100%;
  height: 20px;
}
</style>
